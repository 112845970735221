<template>
  <modal
    ref="modal"
    class-name="h-500px max-w-full pt-28 w-full sm:w-500px"
    @close="open"
    @mounted="emitMountedEvent"
  >
    <template #modal-close>
      <span></span>
    </template>
    <div class="mb-20 relative z-10 text-center">
      <vector
        :src="assets.icons.sm.errorCircle"
        class="flex justify-center mb-8"
        height="80"
        width="80"
      >
        <div class="bg-blue-200 h-80px rounded-full w-80px" />
      </vector>
      <div class="relative z-10">
        <h3 class="font-bold mb-10 text-2xl md:text-4xl">
          Account Blacklisted
        </h3>
        <p class="font-medium mb-5 opacity-75 text-base md:text-lg">
          Your account has been blacklisted due to fraudulent activity.
        </p>
        <!-- <p class="font-medium mb-5 opacity-75 text-base md:text-lg">
          If you have any questions please send an email to us at <a href="mailto:hello@credpal.com">hello@credpal.com</a>
        </p> -->
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AccountBlacklisted',
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$refs.modal.close();
    },
    emitMountedEvent() {
      this.$emit('mounted');
    },
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>
